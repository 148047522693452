<template>
  <div class>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>{{ selected.Code }}</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">{{ selected.CustomerName }}</div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.transaction">
          <vs-td style="align-item-left">{{dateFormat(tr.CreatedAt)}}</vs-td>
          <vs-td style="align-item-center">{{ tr.trx_code }}</vs-td>
          <vs-td style="align-item-center">{{ tr.name }}</vs-td>
          <vs-td style="align-item-right">
            <feather-icon
              v-if="tr.type == 'k'"
              icon="PlusIcon"
              svgClasses="h-5 w-5"
              style="color: green; font-weight: bold"
            ></feather-icon>
            <feather-icon
              v-else
              icon="MinusIcon"
              svgClasses="h-5 w-5"
              style="color: red; font-weight: bold"
            ></feather-icon>
            <br />{{ priceFormat(tr.amount) }}</vs-td
          >
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {},
  props: {
    dataId:Number,
    selected: {},
  },
  // created() {
  // },
  // mounted() {
  // },
  watch: {
    dataId() {
      this.reloadData(this.params);
      // console.log('The dataId has changed!')
    },
  },
  data: () => ({
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "asc",
      sort: "trx_code",
    },
    header: [
      {
        text: "Date",
        value: "date",
        width: '15%'
      },
      {
        text: "Code",
        value: "trx_code",
        // width: '5%'
      },
      {
        text: "Name",
        value: "name",
        // width: '5%'
      },
      {
        text: "Amount",
        value: "amount",
        // width: '5%'
      },
    ],
    responseData: {},
  }),
  methods: {
    dateFormat(date){
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    reloadData(params) {
      console.log("sip");
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/report/customer-limit/" + this.dataId, {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
            order: this.params.order,
            sort: this.params.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.responseData = resp.data;
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
.margin-btm {
  margin-bottom: 1rem !important;
}
.vs-input--input.normal {
  text-align: end;
}
</style>